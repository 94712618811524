import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

const SITE_TITLE = "Вила Вили Ла Коста Лозенец";

export const Seo = ({ pageTitle }) => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          about_us
        }
      }
    }
  `);

  return (
    <Helmet
      htmlAttributes={{
        lang: "bg-BG",
      }}
      title={SITE_TITLE}
      titleTemplate={pageTitle ? `${pageTitle} | %s` : undefined}
      meta={[
        {
          name: `description`,
          content:
            "Хотелът се намира на 2 минути от плажa на Лозенец и разполага с всичко необходимо за вашата лятна почивка. Двойни, тройни стаи и апартаменти.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://www.vililacosta.com/`,
        },
        {
          property: `og:title`,
          content: SITE_TITLE,
        },
        {
          property: `og:description`,
          content:
            "Хотелът се намира на 2 минути от плажa на Лозенец и разполага с всичко необходимо за вашата лятна почивка. Двойни, тройни стаи и апартаменти.",
        },
        {
          property: "og:image",
          content: "https://www.vililacosta.com/img/house.JPG",
        },

        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:url`,
          content: `https://www.vililacosta.com/`,
        },
        {
          name: `twitter:title`,
          content: SITE_TITLE,
        },
        {
          name: `twitter:description`,
          content:
            "Хотелът се намира на 2 минути от плажa на Лозенец и разполага с всичко необходимо за вашата лятна почивка. Двойни, тройни стаи и апартаменти.",
        },
        {
          property: "twitter:image",
          content: "https://www.vililacosta.com/img/house.JPG",
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Hotel",
          name: SITE_TITLE,
          description: markdownRemark.frontmatter.about_us,
          address: {
            "@type": "PostalAddress",
            addressCountry: "BG",
            addressLocality: "Лозенец",
            addressRegion: "Бургас",
            postalCode: "8277",
            streetAddress: "Преображенска 6",
          },
          telephone: "+359-88-586-0719",
          image: "https://www.vililacosta.com/img/house.JPG",
          numberOfRooms: "8",
          starRating: { "@type": "Rating", ratingValue: "2" },
          aggregateRating: {
            "@type": "AggregateRating",
            bestRating: "5",
            ratingValue: "4.6",
            reviewCount: "49",
          },
          priceRange: "76 лв. - 90 лв.",
          petsAllowed: true,
          containsPlace: [
            {
              "@type": "HotelRoom",
              name: "Двойна",
              description:
                "Стая с две легла и баня. Едното легло от което е персон и половина, второто е персон. Стаята е подходяща за малки семейства.",
              image: "https://vililacosta.com/img/double1.jpg",
              occupancy: {
                "@type": "QuantitativeValue",
                minValue: "1",
                maxValue: "2",
              },
            },
            {
              "@type": "HotelRoom",
              name: "Тройна",
              description:
                "Голяма стая с две легла и баня. Едното легло от което е спалня, а другото е персон и половина. Подходяща за малки семейства.",
              image: "https://vililacosta.com/img/double1.jpg",
              occupancy: {
                "@type": "QuantitativeValue",
                minValue: "1",
                maxValue: "3",
              },
            },
            {
              "@type": "HotelRoom",
              name: "Апартамент",
              description:
                "С две стаи и голяма баня. Едната стая на апартамента е спалня, а в другата има две легла от който едното е персон и половина.",
              image: "https://vililacosta.com/img/double1.jpg",
              occupancy: {
                "@type": "QuantitativeValue",
                minValue: "1",
                maxValue: "4",
              },
            },
          ],
        })}
      </script>
    </Helmet>
  );
};
