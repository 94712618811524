import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Seo } from "../components/Seo";

const PrivacyPolicyPage = () => (
  <>
    <Seo pageTitle="Политика за поверителност" />
    <Container>
      <Box mt={10} mb={5}>
        <Typography variant="h3">Политика за поверителност</Typography>
        <Typography variant="caption" gutterBottom>
          Последна актуализация: 01 Януари 2022 г
        </Typography>
        <Typography variant="body1" paragraph>
          Тази Политика за поверителност описва нашите политики и процедури за
          събиране, използване и разкриване на Вашата информация, когато
          използвате Услугата и Ви запознава за Вашите права за поверителност и
          как законът Ви защитава.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Ние използваме Вашите лични данни, за да предоставяме и подобряваме
          Услугата. Използвайки Услугата, Вие се съгласявате със събирането и
          използването на информация в съответствие с тази Политика за
          поверителност.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Тълкуване и определения
        </Typography>
        <Typography variant="h5" gutterBottom>
          Тълкуване
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Думите, чиято начална буква е главна, имат значения, определени при
          следните условия. Определенията имат едно и също значение, независимо
          дали се появяват в единствено или множествено число.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Определения
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          За целите на тази Политика за поверителност:
        </Typography>
        <Typography variant="body1">
          <b>Компанията</b> (наричана „Компанията“, „Ние“, „Нас“ или „Нашата“ в
          това Споразумение) се отнася до ЕТ Ваклина Дичева, ул. Преображенска
          №6 8277 Лозенец Бургас.
        </Typography>
        <Typography variant="body1">
          <b>Бисквитките</b> са малки файлове, които се поставят на Вашия
          компютър, мобилно устройство или друго устройство от уебсайт,
          съдържащи подробности за Вашата история на сърфиране на този уебсайт
          сред многобройните му употреби.
        </Typography>
        <Typography variant="body1">
          <b>Държава</b> се отнася за: България
        </Typography>
        <Typography variant="body1">
          <b>Устройство</b> означава всяко устройство, което има достъп до
          Услугата, като например компютър, мобилен телефон или таблет.
        </Typography>
        <Typography variant="body1">
          <b>Лични данни</b> са всяка информация, която се отнася до
          идентифицирано лице.
        </Typography>
        <Typography variant="body1">
          <b>Услугата</b> се отнася до Уебсайта.
        </Typography>
        <Typography variant="body1">
          <b>Данните за използване</b> се отнасят до данни, събирани
          автоматично, генерирани от използването на Услугата или от самата
          инфраструктура на Услугата (например продължителността на посещението
          на страницата).
        </Typography>
        <Typography variant="body1">
          <b>Уебсайтът</b> се отнася до Вили Ла Коста, достъпен от{" "}
          <a href="https://www.vililacosta.com/">https://www.vililacosta.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Вие</b> означава физическото лице, което осъществява достъп или
          използва Услугата, или компанията, или друго юридическо лице, от името
          на което това лице осъществява достъп или използва Услугата, според
          случая.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Събиране и използване на вашите лични данни
        </Typography>
        <Typography variant="h5" gutterBottom>
          Видове събрани данни
        </Typography>
        <Typography variant="h6" gutterBottom>
          Лични данни
        </Typography>
        <Typography variant="body1" gutterBottom>
          Докато използвате нашата Услуга, Ние може да Ви помолим да ни
          предоставите определена лична информация, която може да се използва за
          връзка или идентифициране с вас. Личната информация може да включва,
          но не се ограничава до:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>Емейл адрес</li>
            <li>Първо име и фамилно име</li>
            <li>Телефонен номер</li>
            <li>Данни за употреба</li>
          </ul>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Данни за употреба
        </Typography>
        <Typography variant="body1" gutterBottom>
          Данните за употреба се събират автоматично при използване на Услугата.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Данните за употреба могат да включват информация като адрес на
          интернет протокола на Вашето устройство (например IP адрес), тип
          браузър, версия на браузъра, страниците на нашата услуга, които
          посещавате, часа и датата на Вашето посещение, времето, прекарано на
          тези страници, уникално устройство идентификатори и други диагностични
          данни.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Когато Вие осъществявате достъп до Услугата чрез или чрез мобилно
          устройство, ние може да събираме определена информация автоматично,
          включително, но не само, вида на мобилното устройство, което
          използвате, уникалния идентификатор на Вашето мобилно устройство, IP
          адреса на Вашето мобилно устройство, Вашия мобилен телефон операционна
          система, типа мобилен интернет браузър, който използвате, уникални
          идентификатори на устройства и други диагностични данни.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ние може също така да събираме информация, която Вашият браузър
          изпраща всеки път, когато посещавате нашата Услуга или когато
          осъществявате достъп до Услугата чрез или чрез мобилно устройство.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Технологии за проследяване и бисквитки
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ние използваме бисквитки и подобни технологии за проследяване, за да
          проследяваме дейността в нашата услуга и да съхраняваме определена
          информация. Използваните технологии за проследяване са маяци, тагове и
          скриптове за събиране и проследяване на информация и за подобряване и
          анализиране на нашата услуга. Технологиите, които използваме, могат да
          включват:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              <b>Бисквитки или бисквитки на браузъра.</b> Бисквитката е малък
              файл, поставен на Вашето устройство. Можете да инструктирате Вашия
              браузър да откаже всички бисквитки или да посочи кога се изпраща
              бисквитка. Въпреки това, ако не приемете бисквитки, може да не
              можете да използвате някои части от нашата услуга. Освен ако не
              сте коригирали настройките на браузъра си така, че да отказва
              бисквитки, нашата услуга може да използва бисквитки.
            </li>
            <li>
              <b>Уеб сигнали.</b> Определени раздели от нашата услуга и нашите
              имейли може да съдържат малки електронни файлове, известни като
              уеб маяци (наричани още ясни gifs, пикселни тагове и еднопикселни
              gifs), които позволяват на Компанията, например, да преброява
              потребителите, които са посетили тези страници или отвори имейл и
              за други свързани статистически данни за уебсайта (например
              записване на популярността на определен раздел и проверка на
              целостта на системата и сървъра).
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Бисквитките могат да бъдат „постоянни“ или „сесийни“ бисквитки.
          Постоянните бисквитки остават на Вашия персонален компютър или мобилно
          устройство, когато излезете офлайн, докато сесийните бисквитки се
          изтриват веднага щом затворите своя уеб браузър. Научете повече за
          бисквитките в статията на уебсайта на Безплатната политика за
          поверителност.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Използване на Вашите лични данни
        </Typography>
        <Typography variant="body1" gutterBottom>
          Компанията може да използва Лични данни за следните цели:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              <b>За предоставяне и поддържане на нашата Услуга</b>, включително
              за наблюдение на използването на нашата Услуга.
            </li>
            <li>
              <b>За управление на Вашия акаунт</b>: за управление на Вашата
              регистрация като потребител на Услугата. Предоставените от вас
              лични данни могат да ви дадат достъп до различни функционалности
              на Услугата, които са достъпни за вас като регистриран потребител.
            </li>
            <li>
              <b>За да се свържем с Вас</b>: За да се свържем с Вас по имейл,
              телефонни обаждания, SMS или други еквивалентни форми на
              електронна комуникация, като насочени известия на мобилно
              приложение относно актуализации или информационни комуникации,
              свързани с функциите, продуктите или договорените услуги,
              включително актуализациите за сигурност, когато е необходимо или
              разумно за тяхното прилагане.
            </li>
            <li>
              <b>За да изпълняване вашите заявки</b>: За да се погрижим и
              управляваме вашите заявки към нас.
            </li>
            <li>
              <b>За други цели</b>: Може да използваме Вашата информация за
              други цели, като анализ на данни, идентифициране на тенденциите в
              употреба, определяне на ефективността на нашите промоционални
              кампании и за оценка и подобряване на нашата услуга, продукти,
              услуги, маркетинг и вашия опит.
            </li>
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom>
          Запазване на Вашите лични данни
        </Typography>
        <Typography variant="body1" gutterBottom>
          Компанията ще съхранява Вашите лични данни само толкова дълго, колкото
          е необходимо за целите, посочени в настоящата Политика за
          поверителност. Ние ще съхраняваме и използваме Вашите лични данни до
          степента, необходима за спазване на нашите законови задължения
          (например, ако от нас се изисква да запазим вашите данни, за да
          спазваме приложимите закони), ще разрешаваме спорове и ще прилагаме
          нашите правни споразумения и политики.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Компанията също така ще съхранява Данни за използване за вътрешен
          анализ. Данните за използване обикновено се съхраняват за по-кратък
          период от време, освен когато тези данни се използват за укрепване на
          сигурността или за подобряване на функционалността на Нашата услуга,
          или ние сме законово задължени да съхраняваме тези данни за по-дълги
          периоди от време.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Разкриване на Вашите лични данни
        </Typography>
        <Typography variant="h6" gutterBottom>
          Служители на реда и държавни агенции
        </Typography>
        <Typography variant="body1" gutterBottom>
          При определени обстоятелства от Компанията може да се изисква да
          разкрие Вашите лични данни, ако това се изисква от закона или в
          отговор на валидни искания от публични органи (например съд или
          правителствена агенция).
        </Typography>
        <Typography variant="h6" gutterBottom>
          Други законови изисквания
        </Typography>
        <Typography variant="body1" gutterBottom>
          Компанията може да разкрие Вашите лични данни с добросъвестно
          убеждение, че такова действие е необходимо за:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>Спазване на законово задължение</li>
            <li>Защита и защита на правата или собствеността на Компанията</li>
            <li>
              Предотвратяване или разследване на възможни неправомерни действия
              във връзка с Услугата
            </li>
            <li>
              Защита на личната безопасност на Потребителите на Услугата или
              обществеността
            </li>
            <li>Защита срещу правна отговорност</li>
          </ul>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Сигурност на Вашите лични данни
        </Typography>
        <Typography variant="body1" gutterBottom>
          Сигурността на Вашите лични данни е важна за Нас, но не забравяйте, че
          нито един метод за предаване през Интернет или метод за електронно
          съхранение не е 100% сигурен. Въпреки че се стремим да използваме
          приемливи от търговска гледна точка средства за защита на Вашите лични
          данни, ние не можем да гарантираме абсолютната им сигурност.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Поверителност на децата
        </Typography>
        <Typography variant="body1" gutterBottom>
          Нашата услуга не е насочена към никого на възраст под 13 години. Ние
          не събираме съзнателно лична информация от лица под 13 години. Ако сте
          родител или настойник и знаете, че Вашето дете ни е предоставило лични
          данни, моля свържете се с нас. Ако разберем, че сме събрали лични
          данни от лице под 13 години без проверка на съгласието на родителите,
          ние предприемаме стъпки за премахване на тази информация от нашите
          сървъри. Ако трябва да разчитаме на съгласието като правно основание
          за обработка на вашата информация и вашата страна изисква съгласие от
          родител, може да изискаме съгласието на вашия родител, преди да
          събираме и използваме тази информация.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Връзки към други уебсайтове
        </Typography>
        <Typography variant="body1" gutterBottom>
          Нашата услуга може да съдържа връзки към други уебсайтове, които не се
          управляват от Нас. Ако щракнете върху връзка на трета страна, ще
          бъдете насочени към сайта на тази трета страна. Силно ви съветваме да
          прегледате Политиката за поверителност на всеки сайт, който
          посещавате. Ние нямаме контрол и не поемаме отговорност за
          съдържанието, политиките за поверителност или практиките на сайтове
          или услуги на трети страни.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Промени в тази Политика за поверителност
        </Typography>
        <Typography variant="body1" gutterBottom>
          Можем да актуализираме нашата Политика за поверителност от време на
          време. Ще Ви уведомим за всякакви промени, като публикуваме новата
          Политика за поверителност на тази страница. Ще Ви уведомим по имейл
          и/или известие на видно място в Нашата услуга, преди промяната да
          влезе в сила и ще актуализираме датата „Последна актуализация“ в
          горната част на тази Политика за поверителност. Препоръчваме ви
          периодично да преглеждате тази Политика за поверителност за всякакви
          промени. Промените в тази Политика за поверителност влизат в сила,
          когато са публикувани на тази страница.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Свържете се с Нас
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ако имате въпроси относно тази Политика за поверителност, можете да се
          свържете с нас:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>По имейл: info@vililacosta.com</li>
            <li>По телефон: +359-88-586-0719</li>
          </ul>
        </Typography>
      </Box>
    </Container>
  </>
);

export default PrivacyPolicyPage;
